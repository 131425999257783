import React, { FC } from 'react';
import { PageLayout } from 'components';

const NotFoundPage: FC = () => (
  <PageLayout title="404: Not found" className="container">
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </PageLayout>
);

export default NotFoundPage;
